.btn:focus {
  outline: none;
  box-shadow: none;
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.rowLetter{
  display: flex;
}

.letterSmall{
  width: 30px;
  height: 30px;
  text-align: center;
  margin-top: auto;
  border: 1px solid black;
  padding: 2px;
  margin: 2px;
}

.corr{
  background-color: var(--correct);
}

.wrng{
  background-color: var(--incorrect);
}

.wrngPos{
  background-color: var(--correctWrongPosition);
}