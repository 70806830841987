.parent {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 80px);
  grid-template-rows: repeat(5, 80px);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 3rem;
}
.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
.div5 {
  grid-area: 1 / 5 / 2 / 6;
}
.div6 {
  grid-area: 2 / 1 / 3 / 2;
}
.div7 {
  grid-area: 2 / 2 / 3 / 3;
}
.div8 {
  grid-area: 2 / 3 / 3 / 4;
}
.div9 {
  grid-area: 2 / 4 / 3 / 5;
}
.div10 {
  grid-area: 2 / 5 / 3 / 6;
}
.div11 {
  grid-area: 3 / 1 / 4 / 2;
}
.div12 {
  grid-area: 3 / 2 / 4 / 3;
}
.div13 {
  grid-area: 3 / 3 / 4 / 4;
}
.div14 {
  grid-area: 3 / 4 / 4 / 5;
}
.div15 {
  grid-area: 3 / 5 / 4 / 6;
}
.div16 {
  grid-area: 4 / 1 / 5 / 2;
}
.div17 {
  grid-area: 4 / 2 / 5 / 3;
}
.div18 {
  grid-area: 4 / 3 / 5 / 4;
}
.div19 {
  grid-area: 4 / 4 / 5 / 5;
}
.div20 {
  grid-area: 4 / 5 / 5 / 6;
}
.div21 {
  grid-area: 5 / 1 / 6 / 2;
}
.div22 {
  grid-area: 5 / 2 / 6 / 3;
}
.div23 {
  grid-area: 5 / 3 / 6 / 4;
}
.div24 {
  grid-area: 5 / 4 / 6 / 5;
}
.div25 {
  grid-area: 5 / 5 / 6 / 6;
}

#incorrect {
  background-color: hsla(210, 14%, 53%, 0.5);
  transition: all 500ms;
}

#correct {
  background-color: mediumseagreen;
  transition: all 500ms;
}

#correctWrongPosition {
  background-color: goldenrod;
  transition: all 500ms;
}

.letter {
  border: 2px solid black;
  border-radius: 10px;
  margin: 10px;
  width: 80px;
  height: 80px;
}

.wrong {
  color: brown;
}

.parent2{
  margin-bottom: -440px;
}


@media only screen and (max-width: 600px) {
  .letter {
    border: 2px solid black;
    border-radius: 10px;
    margin: 5px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .parent {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(5, 60px);
    grid-template-rows: repeat(5, 60px);
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    font-size: 2rem;
  }
  .parent2{
    margin-bottom: -344px;
  }
}

