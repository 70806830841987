body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-bg-color: brown;
  --incorrect: hsla(210, 14%, 53%, 0.502);
  --correct: #3cb371;
  --correctWrongPosition: #daa520;
  --main-bg-colour-keyboard: rgb(224, 224, 224);
}
